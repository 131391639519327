
import {defineComponent, ref, onMounted} from "vue";
import {setCurrentPageTitle} from "@/core/helpers/breadcrumb";
import {l} from '@/core/helpers/lang'
export default defineComponent({
  name: "Sozlesmeler",
  setup() {

    onMounted(() => {
      setCurrentPageTitle(l('admin:transfer:Sözleşmeler'));
    })
    return {
      l
    }
  },
});
